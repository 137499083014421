function isInViewport(element) {
    if (!element) {
        return false;
    }
    const rect = element.getBoundingClientRect();
    return (
        rect.bottom >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
}

export function navHighlighter(selector) {
    const sections = document.querySelectorAll(selector);

    if(sections.length < 1) return;

    // callback function for the intersection observer.
    // init the observer
    const options = {
        rootMargin: "-125px", // 1 pixel more than nav height
        root: document,
        threshold: [],
    }

    const observer = new IntersectionObserver((entries) => {
        // find the index of the section that is currently intersecting
        const indexOfElementIntersecting = entries.findIndex((entry) => {
            // if intersection > 0 it means entry is intersecting with the view port
            return entry.intersectionRatio > 0;
        });

        if(indexOfElementIntersecting >= 0) {
            let currentEntry = entries[indexOfElementIntersecting].target;
            const id = currentEntry.getAttribute('id');

            if(id) {
                document.querySelectorAll('#nav-sidebar > ul > li > a.active').forEach((aEl) => {
                    aEl.classList.remove('active');
                });

                document.querySelector(`[href="#${id}"]`)?.classList.add('active');
            }
        }
    }, options);

    // target the elements to be observed.
    sections.forEach(element =>  observer.observe(element));

    const firstItem = document.querySelector(selector);
    if (isInViewport(firstItem)) {
        const id = firstItem.getAttribute('id');
        document.querySelector(`[href="#${id}"]`)?.classList.add('active');
    }
}