export function horizontalScroller() {
    const containers = document.querySelectorAll('[data-scrollable]');

    if(containers.length < 1) return;

    containers.forEach(container => {
        const scrollableElement = container.querySelector('[data-scroll-list]');
        const buttonElement = container.querySelector('[data-scroll-btn]');

        if(!buttonElement) return;

        const scrollableChildElement = scrollableElement.querySelector('[data-scroll-item]');
        let scrollWidth = scrollableChildElement.offsetWidth;

        const computedStyle = window.getComputedStyle(scrollableElement);
        const gap = computedStyle.getPropertyValue('gap');

        if(gap) {
            scrollWidth += parseInt(gap.replace('px', ''));
        }

        buttonElement.addEventListener('click', () => {
            scrollableElement.scrollBy({
                left: scrollWidth,
                behavior: 'smooth'
            });
        });
    });
}