export function selectReload(selector) {
    const switcherElement = document.querySelector(selector)

    if(switcherElement) {
        switcherElement.addEventListener('change', (event) => {
            const target = event.target;
            if(target) {
                window.location.href = target.value;
            }
        });
    }
}




