function changeNav(entries, observer) {
    let updated = false; // whether the menu was already updated during this intersection.

    entries.forEach((entry) => {
        // if the menu was already updated by a previous entry, we don't need to check the other entries.
        if (updated) {
            return;
        }

        let activeBlock; // the block that should be highlighted in the menu.

        if (!entry.isIntersecting) {

            // This element has just left the viewport. This means at least one of 2 possibilities:
            // 1. the next element has its top aligned with the top of the viewport.
            //    In this case the next element should be activated.
            // 2. the previous element is (at least partly) visible.
            //    In this case we need to keep looking at previous elements until
            //    we find the highest one that is still visible.

            if (isInViewport(entry.target.nextElementSibling)) {

                // possibility 1.
                activeBlock = entry.target.nextElementSibling;

            } else if (isInViewport(entry.target.previousElementSibling)) {

                // possibility 2.
                activeBlock = entry.target.previousElementSibling;
                while (isInViewport(activeBlock.previousElementSibling)) {
                    activeBlock = activeBlock.previousElementSibling;
                }

            }

        } else {

            // This element just entered the viewport. 2 possibilities:
            // 1. The top of the element moved in from the bottom.
            //    In this case nothing needs to happen.
            // 2. The bottom of the element moved in from the top.
            //    In this case the current element should also become active.

            if (!isInViewport(entry.target.nextElementSibling)) {

                // possibility 1.
                return;

            } else if (!isInViewport(entry.target.previousElementSibling)) {

                // possibility 2.
                activeBlock = entry.target;

            }
        }

        // remove active class on all elements.
        document.querySelectorAll('#nav-sidebar > ul > li > a.active').forEach((aEl) => {
            aEl.classList.remove('active');
        });

        // Couldn't determine an active block. So don't do anything.
        if (!activeBlock) {
            return;
        }

        // get id of the intersecting section
        const id = activeBlock.getAttribute('id');

        // find matching link and add appropriate class
        document.querySelector(`[href="#${id}"]`)?.classList.add('active');

        // We're done. Set flag to make sure that we don't check other entries.
        updated = true;

    });
}

function isInViewport(element) {
    if (!element) {
        return false;
    }
    const rect = element.getBoundingClientRect();
    return (
        rect.bottom >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
}

export function navHighlighter(selector) {
    const sections = document.querySelectorAll(selector);

    if(sections.length < 1) return;

    // callback function for the intersection observer.
    // init the observer
    const options = {
        rootMargin: "-125px", // 1 pixel more than nav height
        root: document,
        threshold: [],
    }

    const observer = new IntersectionObserver(changeNav, options);

    // target the elements to be observed.
    sections.forEach((sectionNavTrigger) => {
        observer.observe(sectionNavTrigger);
    });

    const firstItem = document.querySelector(selector);
    if (isInViewport(firstItem)) {
        const id = firstItem.getAttribute('id');
        document.querySelector(`[href="#${id}"]`)?.classList.add('active');
    }
}